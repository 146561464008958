import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/dashboard/Home.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/cookpit/task/:task',
      name: 'add-company',
      component: () => import('@/views/dashboard/Cookpit.vue'),
      meta: {
        middleware: 'auth',
      },
    },
    {
      path: '/verify',
      name: 'verify',
      component: () => import('@/views/auth/Verify.vue'),
      meta: {
        layout: 'full',
        middleware: 'auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        middleware: 'guest',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        layout: 'full',
        middleware: 'guest',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        middleware: 'guest',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
        middleware: 'guest',
      },
    },
    {
      path: '/verify/:token/:pin',
      name: 'verify-from-email',
      component: () => import('@/views/auth/VerifyFromEmail.vue'),
      meta: {
        layout: 'full',
        middleware: 'guest',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.middleware === 'guest') {
    // eslint-disable-next-line no-undef
    if (store.state.auth.authenticated && store.state.auth.token != null) {
      next({ name: 'dashboard' })
    }
    next()
    // eslint-disable-next-line no-undef
  } else if (store.state.auth.authenticated) {
    next()
  } else {
    next({ name: 'login' })
  }
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router
